import React, { useState, Fragment, useEffect } from "react";
import { Form, Container, Row, Col, Button, Navbar } from "react-bootstrap";
import { Formik } from "formik";
import { GoogleSpreadsheet } from "google-spreadsheet";
import cred from "../quickstart-1591255038542-f1a0c450f410.json";
import Logo from "../assets/brcc.jpg";
import navBarImg from "../assets/interface.png";
import { formHeaders, formSchema, formSchemaRows } from "./FormMisc";

export const FormComponent = () => {
  const SPREADSHEET_ID = "1jdl6sR7_iE9GiYDGzjmRtTNQ7cDaqPRAtKoXtkXKl0o";
  var service_id = "default_service";
  var template_id = "blackrifle";
  //const ACCESS_TOKEN = cred.access_token

  const [formValues, setFormValues] = useState({});
  const [validationResult, setValidationResult] = useState(false);
  const [showToast, setShowToast] = useState({ value: false });
  const [rows, setRows] = useState([
    {
      key: 0,
      storeNumber: "",
      address: "",
      city: "",
      state: "",
      zip: "",
      phone: "",
    },
  ]);
  const [savingResponse, setSavingResponse] = useState(false);

  // Function Handlers
  // Add a new site field
  const addSite = () => {
    let tempRows = rows;
    let mapRows = [];
    tempRows.forEach((row) => {
      mapRows.push(row);
    });
    mapRows.push({
      key: rows.length,
      storeNumber: "",
      address: "",
      city: "",
      state: "",
      zip: "",
      phone: "",
    });
    setRows(mapRows);
  };

  // Remove the last site field
  const handleRemoveSite = (key) => {
    let tempRows = rows;
    let mapRows = [];
    tempRows.forEach((row) => {
      mapRows.push(row);
    });
    let filteredRows = mapRows.filter((row) => row.key !== key && row);
    setRows(filteredRows);
  };

  // Handle changes to main response values
  const handleMainFormChange = (e) => {
    setFormValues({ ...formValues, [e.target.name]: e.target.value });
  };

  // Handle changes to site response values
  const handleRowChange = (e, key) => {
    let tempRows = rows;
    let mapRows = [];
    tempRows.forEach((row) => {
      mapRows.push(row);
    });
    mapRows.forEach((row) => {
      if (row.key === key) {
        row[e.target.name] = e.target.value;
      }
    });
    setRows(mapRows);
  };

  // Submit function
  const onSubmit = async () => {
    var template_params = {
      message_html: `You have a new <b>Black Rifle Coffee</b> form submission<br/>Business Legal Name : ${formValues.businessLegalName}<br/>Authorised Signer : ${formValues.authorizedSigner}`,
    };
    if (!validationResult) {
      setSavingResponse(true);
      try {
        const doc = new GoogleSpreadsheet(SPREADSHEET_ID);
        await doc.useServiceAccountAuth(cred);
        await doc.loadInfo();
        const sheet = doc.sheetsByIndex[0];
        const requestArray = [["-"]];
        // Main Response Header
        let mainResponseHeader = formHeaders;
        requestArray.push(mainResponseHeader);
        // Main Response Values
        requestArray.push({
          "Business Legal Name": formValues.businessLegalName,
          "Business Address": formValues.businessAddress,
          "IT Contact - Name": formValues.itName,
          "IT Contact - Phone": formValues.itPhone,
          "IT Contact - Email": formValues.itEmail,
          "Billing Contact - Name": formValues.billingName,
          "Billing Contact - Phone": formValues.billingPhone,
          "Billing Contact - Email": formValues.billingEmail,
          "Other Brands": formValues.otherBrands,
        });
        // Site Response Header
        let siteRows = ["Store#", "Address", "City", "State", "Zip", "Phone"];
        requestArray.push(siteRows);
        rows.forEach((row) => {
          requestArray.push({
            "#":
              "Site" +
              (row.storeNumber.length > 0 ? row.storeNumber : row.key + 1),
            "Business Legal Name": row.address,
            "Authorized Signer": row.city,
            "IT Contact - Name": row.state,
            "IT Contact - Phone": row.zip,
            "IT Contact - Email": row.phone,
          });
        });

        await sheet.addRows(requestArray);
        window.emailjs.send(service_id, template_id, template_params);
        setSavingResponse(false);
        setShowToast({
          value: true,
          type: "success",
          message: "Your response has been submitted successfully.",
        });
        setTimeout(() => window.location.reload(), 3000);
      } catch (error) {
        setSavingResponse(false);
        setShowToast({
          value: true,
          type: "danger",
          message: "There has been an error. Please try again.",
        });
        setTimeout(() => setShowToast({ value: false }), 3000);
      }
    }
  };

  // Validation function
  const validateSchema = async () => {
    const formValueValidation = await formSchema.isValid(formValues);
    const siteValueValidation = await formSchemaRows.isValid(rows);
    setValidationResult(
      formValueValidation ? (siteValueValidation ? false : true) : false
    );
  };

  useEffect(() => {
    validateSchema();
    // eslint-disable-next-line
  }, [rows, formValues]);

  return (
    <div>
      <Navbar
        expand="lg"
        className="py-3"
        style={{
          background: "#fff",
          "border-bottom": "3px solid rgb(246,181,25)",
        }}
      >
        <Container>
          <Navbar.Brand
            href="https://blackrifle.interfacesystems.com/"
            target="_blank"
          >
            <img
              alt=""
              src={navBarImg}
              height="45vh"
              className="d-inline-block align-top"
            />
          </Navbar.Brand>
          <Navbar.Brand href="https://www.blackriflecoffee.com/" target="_blank">
            <img
              alt=""
              src={Logo}
              height="90vh"
              className="d-inline-block align-top"
            />
          </Navbar.Brand>
        </Container>
      </Navbar>
      <Container>
        <Row style={{marginTop:"15px",padding:"7px",backgroundColor:"rgb(246,181,25)"}}>
          <Col>
          <b>Note:</b> Please email <a href="mailto:blackrifle@interfacesys.com">blackrifle@interfacesys.com</a> with a copy of your location blueprint to expedite your quote.
          </Col>
        </Row>
      </Container>
      <Container className="my-5">
        <Formik
          validationSchema={formSchema}
          onSubmit={() => onSubmit()}
          validateOnChange={false}
          validateOnBlur={false}
          initialValues={{}}
        >
          {({ handleSubmit, handleChange, touched, errors }) => (
            <Form onSubmit={handleSubmit}>
              <Row>
                <Col>
                  <Form.Group controlId="row1">
                    <Form.Label className="font-weight-bold">
                      Business Legal Name
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="businessLegalName"
                      value={formValues.businessLegalName || ""}
                      onChange={(e) => {
                        handleChange(e);
                        handleMainFormChange(e);
                      }}
                      isValid={
                        touched.businessLegalName && !errors.businessLegalName
                      }
                      isInvalid={!!errors.businessLegalName}
                    />
                    <Form.Control.Feedback type="invalid">
                      Required field
                    </Form.Control.Feedback>
                    <Form.Control.Feedback></Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group controlId="row2">
                    <Form.Label className="font-weight-bold">
                    Address
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="businessAddress"
                      value={formValues.businessAddress || ""}
                      onChange={(e) => {
                        handleChange(e);
                        handleMainFormChange(e);
                      }}
                      isValid={
                        touched.businessAddress && !errors.businessAddress
                      }
                      isInvalid={!!errors.businessAddress}
                    />
                    <Form.Control.Feedback type="invalid">
                      Required field
                    </Form.Control.Feedback>
                    <Form.Control.Feedback></Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Form.Label className="font-weight-bold">IT Contact</Form.Label>
              <Row>
                <Col md={6} xs={4}>
                  <Form.Group controlId="row3">
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                      type="text"
                      name="itName"
                      value={formValues.itName || ""}
                      onChange={(e) => {
                        handleChange(e);
                        handleMainFormChange(e);
                      }}
                      isValid={touched.itName && !errors.itName}
                      isInvalid={!!errors.itName}
                    />
                    <Form.Control.Feedback type="invalid">
                      Required field
                    </Form.Control.Feedback>
                    <Form.Control.Feedback></Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={3} xs={4}>
                  <Form.Group controlId="row4">
                    <Form.Label>Phone</Form.Label>
                    <Form.Control
                      type="text"
                      name="itPhone"
                      value={formValues.itPhone || ""}
                      onChange={(e) => {
                        handleChange(e);
                        handleMainFormChange(e);
                      }}
                      isValid={touched.itPhone && !errors.itPhone}
                      isInvalid={!!errors.itPhone}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.itPhone}
                    </Form.Control.Feedback>
                    <Form.Control.Feedback></Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={3} xs={4}>
                  <Form.Group controlId="row5">
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      type="email"
                      name="itEmail"
                      value={formValues.itEmail || ""}
                      onChange={(e) => {
                        handleChange(e);
                        handleMainFormChange(e);
                      }}
                      isValid={touched.itEmail && !errors.itEmail}
                      isInvalid={!!errors.itEmail}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.itEmail}
                    </Form.Control.Feedback>
                    <Form.Control.Feedback></Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Form.Label className="font-weight-bold">Billing & Signature Contact</Form.Label>
              <Row>
                <Col md={6} xs={4}>
                  <Form.Group controlId="row3">
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                      type="text"
                      name="billingName"
                      value={formValues.billingName || ""}
                      onChange={(e) => {
                        handleChange(e);
                        handleMainFormChange(e);
                      }}
                      isValid={touched.billingName && !errors.itName}
                      isInvalid={!!errors.billingName}
                    />
                    <Form.Control.Feedback type="invalid">
                      Required field
                    </Form.Control.Feedback>
                    <Form.Control.Feedback></Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={3} xs={4}>
                  <Form.Group controlId="row4">
                    <Form.Label>Phone</Form.Label>
                    <Form.Control
                      type="text"
                      name="billingPhone"
                      value={formValues.billingPhone || ""}
                      onChange={(e) => {
                        handleChange(e);
                        handleMainFormChange(e);
                      }}
                      isValid={touched.billingPhone && !errors.billingPhone}
                      isInvalid={!!errors.billingPhone}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.billingPhone}
                    </Form.Control.Feedback>
                    <Form.Control.Feedback></Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={3} xs={4}>
                  <Form.Group controlId="row5">
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      type="email"
                      name="billingEmail"
                      value={formValues.billingEmail || ""}
                      onChange={(e) => {
                        handleChange(e);
                        handleMainFormChange(e);
                      }}
                      isValid={touched.billingEmail && !errors.billingEmail}
                      isInvalid={!!errors.billingEmail}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.billingEmail}
                    </Form.Control.Feedback>
                    <Form.Control.Feedback></Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md={9}>
                  <Form.Group as={Row}>
                    <Form.Label column sm="9">
                      Do you have any other brands?
                    </Form.Label>
                    <Col sm="3">
                      <Form.Check
                        inline
                        label="Yes"
                        type="radio"
                        name="otherBrands"
                        value="Yes"
                        onChange={(e) => {
                          handleChange(e);
                          handleMainFormChange(e);
                        }}
                        isInvalid={
                          (!formValues.otherBrands && touched.otherBrands) ||
                          !!errors.otherBrands
                        }
                      />
                      <Form.Check
                        inline
                        label="No"
                        type="radio"
                        name="otherBrands"
                        value="No"
                        onChange={(e) => {
                          handleChange(e);
                          handleMainFormChange(e);
                        }}
                        isInvalid={
                          (!formValues.otherBrands && touched.otherBrands) ||
                          !!errors.otherBrands
                        }
                      />
                    </Col>
                  </Form.Group>
                </Col>
                <Col md={3}>
                  {/* <Row
                    className="text-center align-items-center"
                    style={{ height: "100%" }}
                  >
                    <img src={Logo} alt="sjsLogo" className="formLogo" />
                  </Row> */}
                </Col>
              </Row>
              <Form.Label className="font-weight-bold mt-3">
                Site List
              </Form.Label>
              {rows.map((row, index) => (
                <Fragment key={row.key}>
                  <Row className="mt-3">
                    <Col sm={1}>
                      <Form.Group>
                        <Form.Label>Store#</Form.Label>
                        <Form.Control
                          type="text"
                          name="storeNumber"
                          onChange={(e) => handleRowChange(e, row.key)}
                        />
                      </Form.Group>
                    </Col>
                    <Col sm={3}>
                      <Form.Group>
                        <Form.Label>Address</Form.Label>
                        <Form.Control
                          type="text"
                          name="address"
                          value={row.address}
                          onChange={(e) => handleRowChange(e, row.key)}
                          isValid={row.address.length > 0}
                        />
                        <Form.Control.Feedback></Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col sm={2}>
                      <Form.Group>
                        <Form.Label>City</Form.Label>
                        <Form.Control
                          type="text"
                          name="city"
                          value={row.city}
                          onChange={(e) => handleRowChange(e, row.key)}
                          isValid={row.city.length > 0}
                        />
                        <Form.Control.Feedback></Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col sm={2}>
                      <Form.Group>
                        <Form.Label>State</Form.Label>
                        <Form.Control
                          as="select"
                          name="state"
                          onChange={(e) => handleRowChange(e, row.key)}
                          isValid={row.state && row.state.length > 0}
                        >
                          <option value="">Select</option>
                          <option value="AL">Alabama</option>
                          <option value="AK">Alaska</option>
                          <option value="AZ">Arizona</option>
                          <option value="AR">Arkansas</option>
                          <option value="CA">California</option>
                          <option value="CO">Colorado</option>
                          <option value="CT">Connecticut</option>
                          <option value="DE">Delaware</option>
                          <option value="DC">District Of Columbia</option>
                          <option value="FL">Florida</option>
                          <option value="GA">Georgia</option>
                          <option value="HI">Hawaii</option>
                          <option value="ID">Idaho</option>
                          <option value="IL">Illinois</option>
                          <option value="IN">Indiana</option>
                          <option value="IA">Iowa</option>
                          <option value="KS">Kansas</option>
                          <option value="KY">Kentucky</option>
                          <option value="LA">Louisiana</option>
                          <option value="ME">Maine</option>
                          <option value="MD">Maryland</option>
                          <option value="MA">Massachusetts</option>
                          <option value="MI">Michigan</option>
                          <option value="MN">Minnesota</option>
                          <option value="MS">Mississippi</option>
                          <option value="MO">Missouri</option>
                          <option value="MT">Montana</option>
                          <option value="NE">Nebraska</option>
                          <option value="NV">Nevada</option>
                          <option value="NH">New Hampshire</option>
                          <option value="NJ">New Jersey</option>
                          <option value="NM">New Mexico</option>
                          <option value="NY">New York</option>
                          <option value="NC">North Carolina</option>
                          <option value="ND">North Dakota</option>
                          <option value="OH">Ohio</option>
                          <option value="OK">Oklahoma</option>
                          <option value="OR">Oregon</option>
                          <option value="PA">Pennsylvania</option>
                          <option value="RI">Rhode Island</option>
                          <option value="SC">South Carolina</option>
                          <option value="SD">South Dakota</option>
                          <option value="TN">Tennessee</option>
                          <option value="TX">Texas</option>
                          <option value="UT">Utah</option>
                          <option value="VT">Vermont</option>
                          <option value="VA">Virginia</option>
                          <option value="WA">Washington</option>
                          <option value="WV">West Virginia</option>
                          <option value="WI">Wisconsin</option>
                          <option value="WY">Wyoming</option>
                          <option value="AS">American Samoa</option>
                          <option value="GU">Guam</option>
                          <option value="MP">Northern Mariana Islands</option>
                          <option value="PR">Puerto Rico</option>
                          <option value="UM">
                            United States Minor Outlying Islands
                          </option>
                          <option value="VI">Virgin Islands</option>
                        </Form.Control>
                        <Form.Control.Feedback></Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col sm={2}>
                      <Form.Group>
                        <Form.Label>Zip</Form.Label>
                        <Form.Control
                          type="text"
                          name="zip"
                          value={row.zip}
                          onChange={(e) => handleRowChange(e, row.key)}
                          isValid={
                            row.zip.length > 0 &&
                            row.zip.match(/^\d{5}(?:[-\s]\d{4})?$/)
                          }
                          isInvalid={
                            row.zip.length === 0
                              ? false
                              : !row.zip.match(/^\d{5}(?:[-\s]\d{4})?$/)
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          {row.zip.length > 0 ? "Not a valid zip code" : ""}
                        </Form.Control.Feedback>
                        <Form.Control.Feedback></Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col sm={2}>
                      <Form.Group>
                        <Form.Label>Phone</Form.Label>
                        <Form.Control
                          type="text"
                          name="phone"
                          value={row.phone}
                          onChange={(e) => handleRowChange(e, row.key)}
                          isValid={row.phone.length > 0}
                        />
                        <Form.Control.Feedback type="invalid">
                          {row.phone.length > 0
                            ? "Not a valid phone number"
                            : ""}
                        </Form.Control.Feedback>
                        <Form.Control.Feedback></Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>
                  {index === rows.length - 1 && index > 0 && (
                    <Row
                      className="justify-content-end"
                      style={{
                        width: "100%",
                        margin: "0px",
                      }}
                    >
                      <h6
                        className="text-danger"
                        onClick={() => handleRemoveSite(row.key)}
                        style={{ cursor: "pointer" }}
                      >
                        Remove
                      </h6>
                    </Row>
                  )}
                </Fragment>
              ))}
              <Row
                className="justify-content-end mt-3"
                style={{
                  width: "100%",
                  margin: "0px",
                }}
              >
                <Button variant="primary" onClick={addSite}>
                  Add Another Site
                </Button>
              </Row>
              <Row
                className="mt-5 justify-content-center"
                style={{ width: "100%" }}
              >
                <Button
                  variant="outline-dark"
                  size="lg"
                  type="submit"
                  disabled={savingResponse}
                >
                  {savingResponse ? "Loading" : "Submit"}
                </Button>
              </Row>
            </Form>
          )}
        </Formik>
        <Row className="justify-content-center">
          {showToast.value && (
            <div className={`toast_div toast_color_${showToast.type}`}>
              <div className="toast_text">{showToast.message}</div>
            </div>
          )}
        </Row>
      </Container>
    </div>
  );
};

export default FormComponent;
